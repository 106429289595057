// src/components/CourseList.js
import React from 'react';
import { Link } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import './courseListStyles.css';
import { courses } from '../courseDataLoader'; // Importing shared course data
import { projects } from '../projectLoader'; // Importing shared project data
import { Tooltip } from 'react-tooltip'; // Import Tooltip
import posthog from 'posthog-js';

const CourseList = ({ session, isProject = false, title = "Catalog", description = "Explore our courses catalog" }) => {
  const items = isProject ? projects : courses;

  const handleItemStart = (item) => {
    if (isProject) {
      posthog.capture('Project Start', {
        itemId: item.id,
        itemTitle: item.title,
        userId: session?.user?.id || 'anonymous'
      });
    } else {
      posthog.capture('Course Start', {
        courseId: item.id,
        courseTitle: item.title,
        userId: session?.user?.id || 'anonymous'
      });
    }
  };

  return (
    <div className="pt-4">
      <CustomNavbar title="Hourandcode" session={session} showProgress={false} />
      <div className="container lg-col-6 mt-4">
        <div className="row">
          <div className="col-12 text-center mt-4">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
        <div className="course-list mt-4 mb-4">
          {items.map((item) => (
            <div className="course-card shadow-sm" key={item.id}>
              <img src={item.thumbnail} alt={`${item.title} thumbnail`} className="course-thumbnail" />
              <div className="course-info">
                <h2>
                  {item.title}
                  {item.live === "true" && <span className="live-badge badge text-bg-warning" data-tooltip-id="live-tooltip" data-tooltip-content="Exclusive Weekly Office Hour">Beta Release</span>}
                </h2>
                <Tooltip id="live-tooltip" className="tooltip"/>
                <p>{item.description}</p>
                {item.live === "true" && <p className="in-development">Early Access ✨</p>}
                <Link to={isProject ? `/projects/${item.id}` : `/courses/${item.id}`}>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleItemStart(item)}
                  >
                    Start →
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseList;
