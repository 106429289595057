import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import TextContent from './TextContent/TextContent';
import InputContent from './InputContent/InputContent';
import CodeContent from './CodeContent/CodeContent';
import QuizContent from './QuizContent/QuizContent';
import VideoContent from './VideoContent/VideoContent';
import InstructionContent from './InstructionContent/InstructionContent';
import HtmlContent from './HtmlContent/HtmlContent';
import InputHTMLContent from './InputHTMLContent/InputHTMLContent';
import InputShellContent from './InputShellContent/InputShellContent';
import ShellContent from './ShellContent/ShellContent';
import ImageContent from './ImageContent/ImageContent';
import ClipboardContent from './ClipboardContent/ClipboardContent';
import ShowReference from './ShowReference/ShowReference';
import ProgressBar from '../Navbar/ProgressBar';
import Checkout from "../Checkout/Checkout";
import { Button } from 'react-bootstrap';
import { supabase } from '../../utils/supabase';
import './lessonStyles.css';
import CertificateContent from './CertificateContent/CertificateContent';
import posthog from 'posthog-js';
import CodeDiffContent from './CodeDiffContent/CodeDiffContent';
import LessonHeader from './LessonHeader/LessonHeader';

const Lesson = ({session, account}) => {

  const { id, levelId, lessonId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [inputValue, setInputValue] = useState(''); // Add this line
  const [courseData, setCourseData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showReference, setShowReference] = useState(false);
  const [reset, setReset] = useState(false);
  const containerRef = useRef(null);
  const [courseProgress, setCourseProgress] = useState({}); // {{ edit_1 }}
  const lockSVG = '/images/lock.svg';
  const [currentUser, setCurrentUser] = useState(null);
  const [userCourse, setUserCourse] = useState(null);
  const [userCourseFetched, setUserCourseFetched] = useState(false);
  const [userCourseLessonFetched, setUserCourseLessonFetched] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentLesson, setCurrentLesson] = useState(null);
  // const [fetchedCourseId, setFetchedCourseId] = useState(null);
  // const [fetchedLevelId, setFetchedLevelId] = useState(null);
  // const [fetchedLessonId, setFetchedLessonId] = useState(null);
  const [fetchedSavedProgress, setFetchedSavedProgress] = useState(null);
  const [quizResults, setQuizResults] = useState({});
  const buttonRef = useRef(null);
  const isProject = location.pathname.includes('/projects/');

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []); //

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Page is visible, do not reload
        setUserCourseLessonFetched(true); // Set pageLoaded to true
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const storageBucket = isProject ? 'project-json-public' : 'course-json-public';
        const dataFileName = isProject ? 'projectData.json' : 'courseData.json';
        const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${storageBucket}/${id}/${dataFileName}`);
        // Debug
        // const response = await fetch('http://127.0.0.1:3007/json-data');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCourseData(data);
        const level = data.levels[levelId];
        setCurrentLevel(level);
        const lesson = level.lessons.find((lesson) => lesson.id === lessonId);
        setCurrentLesson(lesson);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchCourseData();
  }, [id, lessonId, levelId]);

  useEffect(() => {
    setUserCourseFetched(false);
    setUserCourseLessonFetched(false);
  }, [id, levelId, lessonId]);

  useEffect(() => {
    if (!currentUser) {
      const fetchUserData = async () => {
        if (session && session.user && (account && (account.stripe_subscription_status === 'active'))) {
            const { email } = session.user;

            // Query the Accounts table for the authenticated user's email
            const { data, error } = await supabase
              .from('Accounts')
              .select('paid')
              .eq('email', email)
              .single(); // Get a single record

            if (error) {
              console.error("Error fetching user data:", error);
              setCurrentUser({ paid: false }); // Default to false if there's an error
            } else if (data) {
              // console.log("data: " + JSON.stringify(data))
              setCurrentUser({ paid: data.paid }); // Set the paid status from the fetched data
            }
        } else {
          setCurrentUser({ paid: false }); // Default if session is not available
        }
      };

      fetchUserData();
    }
  }, [session, account]); // Depend on session to fetch user data when it changes


  useEffect(() => {
    // Existing early return conditions
    // console.log('useEffect fetching saved progress triggered');
    // console.log('fetchedSavedProgress:', fetchedSavedProgress);

    if (userCourseFetched === true){
      // console.log('Already fetched user course');
      return;
    };

    const fetchSavedProgress = async () => {
      if (fetchedSavedProgress === true) {
        // console.log('already fetched saved progress')
        return;
      }

      if (!session || !session.user) {
        return; // Exit if session is not available
      }

      if (userCourseFetched === false && userCourseLessonFetched ===  false) {
        // console.log('course_id: ' + courseData.id )
        const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('*')
          .eq('user_id', session.user.id) // Get user ID from session
          .eq('course_id', courseData.id) // Match with courseData.id
          .single(); // Get a single record

        if (userCourseError && (userCourseError.code !== 'PGRST116')) {
          console.error("Error fetching UserCourse:", userCourseError);
          return; // Exit if there's an error
        } else if (userCourseError && (userCourseError.code === 'PGRST116')) {
          // console.log("No UserCourse found, creating a new one.");
          // Create UserCourse if not found
          const { error: insertError } = await supabase
            .from('UserCourses')
            .insert([{ user_id: session.user.id, course_id: courseData.id, title: currentLesson.title, completed: false }]);

          if (insertError) {
            console.error("Error creating UserCourse:", insertError);
            return; // Exit if there's an error
          }
        }

        // console.log('userCourseData::: ' +  JSON.stringify(userCourseData))
        if (userCourseData) {
          setUserCourseFetched(true);
          // console.log('useCourseData already created')
          // Set userCourse to the retrieved data
          setUserCourse(userCourseData);

          // Now fetch the saved progress from UserCourseLessons
          const { data, error: progressError } = await supabase
            .from('UserCourseLessons')
            .select('*')
            .eq('user_course_id', userCourseData.id) // Use the UserCourse ID
            .eq('lesson_id', lessonId)
            .eq('user_id', session.user.id);

          if (progressError  && (progressError.code !== 'PGRST116')) {
            console.error("Error fetching saved progress:", progressError);
            return; // Exit if there's an error
          } else if (progressError && (progressError.code === 'PGRST116')) {
            console.log('error PGRST116')
          }

          if (data && data.length > 0) {
            setUserCourseLessonFetched(true);

            const progress = data[0].progress || {};
            const initialStep = Object.keys(progress).filter(key => key !== 'progress').length || 0;

            // console.log('Progress after reset:', progress);
            // console.log('Initial step after reset:', initialStep);

            setCurrentStep(initialStep);
            setCourseProgress(progress);
            // setProgressData(progress);
          } else {
            // console.log("No UserCourseLesson found, creating a new one.");

            // Check if the record already exists
            const { data: existingRecord, error: fetchError } = await supabase
              .from('UserCourseLessons')
              .select('*')
              .eq('user_id', session.user.id)
              .eq('user_course_id', userCourseData.id)
              .eq('lesson_id', lessonId)
              .single();

            if (fetchError && fetchError.code !== 'PGRST116') {
              console.error("Error fetching UserCourseLesson:", fetchError);
              return; // Exit if there's an error
            }

            if (!existingRecord) {
              // Insert only if the record does not exist
              const { error: insertError } = await supabase
                .from('UserCourseLessons')
                .insert([{
                  user_id: session.user.id,
                  user_course_id: userCourseData.id,
                  lesson_id: lessonId,
                }]);

              if (insertError) {
                console.error("Error creating UserCourseLesson:", insertError);
                return; // Exit if there's an error
              }
            }
            // Handle case where no progress is found
            setCurrentStep(0);
            setCourseProgress({});
            setFetchedSavedProgress(true)
          }
        }  else {
          // console.log('nooo userCourseData')
        }
      }
    };


    const checkSession = async () => {
      const { data: sessionData } = await supabase.auth.getSession();
      if (sessionData) {

        if (courseData) {
          // Fetch progress from Supabase when courseData is available
          fetchSavedProgress();
        }
      } else {
        navigate('/authentication');
      }
    };

    checkSession();
  }, [session, navigate, courseData, id, levelId, lessonId, reset, currentLesson?.title, userCourseFetched, userCourseLessonFetched]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentLesson && !userCanView(currentLesson)) {
      posthog.capture('Viewed Locked Lesson', {
        lessonId: lessonId,
        levelId: levelId,
        courseId: id,
      });
    }
  }, [currentLesson, lessonId, levelId, id]);

  const handleCompleteStep = async (progressUpdate) => {
    const { result, input, error } = progressUpdate;

    if (session || session?.user) {
      // Update UserCourseLesson is session exists
      // console.log('userCourse: ' + JSON.stringify(userCourse))
      const { data, error: lessonError } = await supabase
        .from('UserCourseLessons')
        .select('*')
        .eq('user_course_id', userCourse.id) // Use the UserCourse ID
        .eq('lesson_id', lessonId)
        .eq('user_id', session.user.id)
        .single();

      if (lessonError && (lessonError.code !== 'PGRST116') ) {
        console.error("Main: Error fetching lesson progress:", lessonError);
      } else if (lessonError && lessonError.code === 'PGRST116' ){
        console.log('404 – The result contains 0 rows');
      } else if (data) {
        // console.log('updating progress.....')
        // Update existing lesson progress
        try {
          // Update existing lesson progress
          const { error: updateError } = await supabase
            .from('UserCourseLessons')
            .update({
              progress: { ...data.progress, [currentStep]: { result, input, error } }
            })
            .eq('id', data.id);

          if (updateError) {
            throw new Error(`Error updating lesson progress: ${updateError.message}`);
          }
          // Track step completion
          posthog.capture('Lesson Step Completed', {
            courseId: id,
            lessonId: lessonId,
            lessonTitle: currentLesson.title,
            stepIndex: currentStep,
            userId: session.user.id
          });

        } catch (err) {
          console.error(err);
          // Handle the error (e.g., show a notification to the user)
          return; // Exit if there's an error
        }
      } else {
        console.error("No lesson progress found for update.");
      }
    }

    // Track quiz results
    if (currentLesson.steps[currentStep]?.type === 'quiz') {
      setQuizResults((prevResults) => ({ ...prevResults, [currentStep]: result }));
    }


    // Update isCorrect based on the result
    if (result) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
    }

    // Scroll to the bottom after state update
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  };

  const handleContinue = () => {
    // Always call onComplete regardless of correctness for "Continue"
    const progressUpdate = {
        input: inputValue,
        result: true, // Assume the user wants to continue
    };

    handleCompleteStep(progressUpdate);

    // Scroll to the bottom after state update
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  };

  const handleToggleReference = () => {
    setShowReference(!showReference);
  };

  const handleLastStep = async () => {
    if (session && session.user && (account && (account.stripe_subscription_status === 'active'))) {
      const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('*')
          .eq('user_id', session.user.id)
          .eq('course_id', id)
          .single();

      if (userCourseError) {
          console.error("Error fetching UserCourse:", userCourseError);
          return; // Exit if there's an error
      }

      const { error } = await supabase
        .from('UserCourses')
        .update({
          lessons_completed: {
            ...userCourseData.lessons_completed, // Spread existing completed lessons
            [lessonId]: true // Set the current lessonId to true
          }
        })
        .eq('user_id', session.user.id) // Ensure to update the correct user
        .eq('course_id', id) // Ensure to update the correct course
        .single();

      // console.log('lessonId: ' + JSON.stringify(lessonId))

      if (error) {
        console.error("Error updating lesson completion status:", error);
        return; // Handle the error as needed
      } else {
        try {
          // Update existing lesson progress
          const { error: updateError } = await supabase
            .from('UserCourseLessons')
            .update({
              completed: true
            })
            .eq('lesson_id', lessonId)
            .eq('user_course_id', userCourseData.id)
            .eq('user_id', session.user.id)
            .single()

          if (updateError) {
            throw new Error(`Error updating lesson completed state: ${updateError.message}`);
          }

          // Track the "Lesson Completed" event in PostHog
          posthog.capture('Lesson Completed', {
            courseId: id,
            lessonId: lessonId,
            lessonTitle: currentLesson.title,
            userId: session.user.id
          });

        } catch (err) {
          console.error(err);
          // Handle the error (e.g., show a notification to the user)
          return; // Exit if there's an error
        }
      }
    }

    handleCompleteStep({result: true});
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  };

  const handleReset = async () => {
    // console.log('handleReset called');

    if (session && session.user) {
      // Fetch UserCourse based on course_id
      const { data: userCourseData, error: userCourseError } = await supabase
        .from('UserCourses')
        .select('*')
        .eq('user_id', session.user.id)
        .eq('course_id', id)
        .single();

      if (userCourseError) {
        console.error("Error fetching UserCourse:", userCourseError);
        return; // Exit if there's an error
      }

      if (userCourseData) {
        // Fetch UserCourseLessons based on UserCourse ID and lesson ID
        const { data: lessonData, error: lessonError } = await supabase
          .from('UserCourseLessons')
          .select('*')
          .eq('user_course_id', userCourseData.id)
          .eq('lesson_id', lessonId)
          .single();

        if (lessonError) {
          console.error("Error fetching lesson progress:", lessonError);
          return; // Exit if there's an error
        }

        if (lessonData) {
          // Reset the progress in UserCourseLessons
          const { error: updateError } = await supabase
            .from('UserCourseLessons')
            .update({ progress: {} }) // Reset progress to an empty object
            .eq('id', lessonData.id);

          if (updateError) {
            console.error("Error resetting lesson progress:", updateError);
          }
        }
      }

      window.scrollTo(0, 0);
    }

    // Log before updating state
    // console.log('Before reset - currentStep:', currentStep);
    // console.log('Before reset - reset:', reset)

    setReset((prevReset) => prevReset + 1);
    setCurrentStep(0);
    setQuizResults({});
    setFetchedSavedProgress(false); // Add this line
    // console.log('Set fetchedSavedProgress to false after reset');
    // // Log after updating state
    // console.log('After reset - currentStep:', currentStep);
    // console.log('After reset - reset:', reset);
  };


  // Ensure courseData is loaded before accessing it
  if (!courseData) {
    return <div>Loading...</div>;
  }

  // Check if levels exist

  if (!currentLevel) {
    console.error("Level not found for levelId:", levelId);
    return <div>Error: Level not found.</div>;
  }

  // Check if lessons exist
  if (!currentLesson) {
    console.error("Lesson not found for lessonId:", lessonId);
    return <div>Error: Lesson not found.</div>;
  }

  // Check if lesson has steps
  if (!currentLesson.steps) {
    console.error("Steps not found for lesson:", currentLesson);
    return <div>Error: Steps not found for this lesson.</div>;
  }

  // Now you can safely access steps
  // const currentStepData = lesson.steps[currentStep];
  // if (!currentStepData) {
  //   console.error("Current step not found:", currentStep);
  //   return <div>Error: Current step not found.</div>;
  // }

  const isLastStep = (currentStep === currentLesson.steps.length - 1);
  const isCourseCompleted = currentStep >= currentLesson.steps.length;

  // console.log('isCourseCompleted: ' + isCourseCompleted)
  const progress = (currentStep / currentLesson.steps.length) * 100;

  const userCanView = (lesson) => {
    if (lesson.locked === 'true') {
      if (session && session.user && (currentUser && currentUser.paid === true)) {
        return true;
      } else {
        return false;
      }
    } else {
     return true;
    }
  }

  // console.log("Course Data:", courseData);
  // console.log("Level ID:", levelId);
  // console.log("Lesson ID:", lessonId);
  // console.log("Current Step:", currentStep);
  const getNextLessonId = () => {
    // Convert levels object to an array and sort it numerically based on the level IDs
    const levelsArray = Object.entries(courseData.levels)
      .sort(([idA], [idB]) => {
        const numA = parseInt(idA.replace(/\D/g, ''));
        const numB = parseInt(idB.replace(/\D/g, ''));
        return numA - numB;
      })
      .map(([id, level]) => ({ ...level, id })); // Place 'id' after spreading 'level'

    const currentLevelIndex = levelsArray.findIndex(level => level.id === levelId);

    if (currentLevelIndex === -1) {
      console.error("Current level not found");
      return null;
    }

    // If lessons are objects, convert them to arrays and sort them
    let lessonsArray = levelsArray[currentLevelIndex].lessons;
    if (!Array.isArray(lessonsArray)) {
      lessonsArray = Object.entries(lessonsArray)
        .sort(([idA], [idB]) => {
          const numA = parseInt(idA.replace(/\D/g, ''));
          const numB = parseInt(idB.replace(/\D/g, ''));
          return numA - numB;
        })
        .map(([id, lesson]) => ({ ...lesson, id }));
    }

    const currentLessonIndex = lessonsArray.findIndex(lesson => lesson.id === lessonId);

    // console.log("levelsArray:", levelsArray);
    // console.log("currentLevelIndex:", currentLevelIndex);
    // console.log("lessonsArray:", lessonsArray);
    // console.log("currentLessonIndex:", currentLessonIndex);
    // console.log("Next Lesson Info:", getNextLessonId());

    if (currentLessonIndex === -1) {
      console.error("Current lesson not found");
      return null;
    }

    // Check if there's another lesson in the current level
    if (currentLessonIndex < lessonsArray.length - 1) {
      const nextLesson = lessonsArray[currentLessonIndex + 1];
      return { levelId: levelId, lessonId: nextLesson.id };
    }

    // Check if there's another level
    if (currentLevelIndex < levelsArray.length - 1) {
      const nextLevel = levelsArray[currentLevelIndex + 1];

      // Handle lessons in the next level
      let nextLevelLessons = nextLevel.lessons;
      if (!Array.isArray(nextLevelLessons)) {
        nextLevelLessons = Object.entries(nextLevelLessons)
          .sort(([idA], [idB]) => {
            const numA = parseInt(idA.replace(/\D/g, ''));
            const numB = parseInt(idB.replace(/\D/g, ''));
            return numA - numB;
          })
          .map(([id, lesson]) => ({ ...lesson, id }));
      }

      if (nextLevelLessons && nextLevelLessons.length > 0) {
        const nextLesson = nextLevelLessons[0];
        return { levelId: nextLevel.id, lessonId: nextLesson.id };
      }
    }

    // If no next lesson, return null
    console.log("No next lesson available.");
    return null;
  };


  // const handleNextLesson = () => {
  //   const nextLessonInfo = getNextLessonId();
  //   if (nextLessonInfo) {
  //     const { levelId: nextLevelId, lessonId: nextLessonId } = nextLessonInfo;
  //     // Capture the "Next Lesson" event in PostHog
  //     posthog.capture('Next Lesson Clicked', {
  //       currentCourseId: courseId,
  //       currentLessonId: lessonId,
  //       nextLessonId: nextLessonId,
  //       nextLevelId: nextLevelId
  //     });
  //     console.log("nextLessonId: " + nextLessonId);

  //     // Navigate to the next lesson with the correct URL structure
  //     navigate(`/courses/${courseId}/${nextLevelId}/${nextLessonId}`);
  //   }
  // };

  const renderStep = (step, index) => {
    const stepProgress = courseProgress[index];
    const isStepCompleted = stepProgress && stepProgress.result;

    const commonProps = {
      step,
      onComplete: handleCompleteStep,
      courseId: id,
      lessonId,
      levelId,
      stepIndex: index,
      reset,
      session,
      stepCompleted: isStepCompleted,
    };

    switch (step.type) {
      case 'text':
        return (
          <div>
            <TextContent content={step.content} />
            {index === currentStep && !isLastStep && (
              <div className="d-flex justify-content-end">
                <Button
                  className="mt-2 w-100 w-md-auto"
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        );
      case 'input':
        return (
          <InputContent
            {...commonProps}
            inputValue={inputValue}
            setInputValue={setInputValue}
            courseProgress={courseProgress}
          />
        );
      case 'code':
        return <CodeContent {...commonProps} />;
      case 'image':
        return <ImageContent {...commonProps} src={step.src} alt={step.alt} />;
      case 'instruction':
        return <InstructionContent {...commonProps} />;
      case 'quiz':
        return <QuizContent {...commonProps} />;
      case 'video':
        return <VideoContent {...commonProps} />;
      case 'certificate':
        return <CertificateContent {...commonProps} />;
      case 'inputhtml':
        return <InputHTMLContent {...commonProps} />;
      case 'html':
        return <HtmlContent {...commonProps} />;
      case 'inputshell':
        return <InputShellContent {...commonProps} />;
      case 'shell':
        return <ShellContent {...commonProps} />;
      case 'clipboard':
        return (
          <ClipboardContent
            {...commonProps}
            onContinue={() => handleCompleteStep({ result: true, input: null })}
            courseProgress={courseProgress}
          />
        );
      case 'codediff':
        return (
          <CodeDiffContent
            {...commonProps}
            onContinue={() => handleCompleteStep({ result: true, input: null })}
            courseProgress={courseProgress}
          />
        );
      default:
        return null;
    }
  };

  const isProjectPath = location.pathname.includes('/projects/');

  return (
    <>
      <CustomNavbar
        progress={progress}
        session={session}
        onBack={() => navigate(isProjectPath ? `/projects/${id}` : `/courses/${id}`)}
      />
      {!courseData ? (
        <div>Loading...</div>
      ) : !currentLevel ? (
        <div>Error: Level not found.</div>
      ) : !currentLesson ? (
        <div>Error: Lesson not found.</div>
      ) : !currentLesson.steps ? (
        <div>Error: Steps not found for this lesson.</div>
      ) : (
        <>
          {progress && (
            <div className="progress-container-mobile d-block d-md-none mt-2">
              <span>Progress:</span>
              <ProgressBar progress={progress} />
            </div>
          )}
          <div className="container col-md-6 col-12 mt-5" ref={containerRef}>
            {/* <div className="d-block d-md-block" style={{ paddingTop: '4rem' }}></div> Spacer for mobile */}
            <div className="d-block d-md-none" style={{ paddingTop: '2rem' }}></div>
            <div className="d-flex justify-content-end">
              <a
                className="text-danger mt-4 text-decoration-none"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleReset();
                  posthog.capture('Clicked Reset Lesson', {
                    lessonId: lessonId,
                    levelId: levelId,
                    courseId: id,
                  });
                }}
              >
                Reset Lesson
              </a>
            </div>
            <span className="badge badge-dark mb-2">✨ premium</span>
            {!isProjectPath && (
            <h1>{currentLesson.title}</h1>
            )}
            {userCanView(currentLesson) && (
              <>
                <div className="app-container pt-4 pt-md-0">
                  {isProjectPath && (
                    <LessonHeader
                      epic={currentLevel.title}
                      title={currentLesson.title}
                      description={currentLesson.description}
                      dependencies={currentLesson.dependencies}
                      acceptanceCriteria={currentLesson.acceptanceCriteria}
                    />
                  )}
                  <div className="steps">
                    {currentLesson.steps.slice(0, currentStep + 1).map((step, index) => (
                      <div key={index} className="step-container">
                        {renderStep(step, index)}
                      </div>
                    ))}
                  </div>
                  {isLastStep && (
                    <Button className="mt-2 w-100 w-md-auto" onClick={handleLastStep}>
                      Finish Lesson
                    </Button>
                  )}

                  {isCourseCompleted && (
                    <>
                      {currentLesson.quizPassScore !== undefined ? (
                        quizResults && Object.values(quizResults).reduce((acc, val) => acc + val, 0) >= currentLesson.quizPassScore ? (
                          <p><b>Congratulations! You've passed this lesson.</b></p>
                        ) : (
                          <p><b>Unfortunately, you did not pass the lesson. Please try again.</b></p>
                        )
                      ) : (
                        <p><b>Congratulations! You've completed this lesson.</b></p>
                      )}

                      { (!session || !session.user) && (
                        <div className="mt-4 mb-4">
                          <p>You've just completed this lesson, but your progress won't be saved without an account. With an account, you can track your progress, unlock all premium lessons, and continue where you left off. Create your account now to enjoy all the benefits!</p>
                          <div className="pricing-container">
                              <Button
                                className="unlock-button"
                                variant="warning"
                                onClick={() => {
                                  // Track the "Upgrade Attempt" event in PostHog
                                  posthog.capture('Upgrade Attempt', {
                                    source: 'lesson_completion',
                                    courseId: id,
                                    lessonId: lessonId,
                                    lessonTitle: currentLesson.title
                                  });

                                  // Navigate to the upgrade page
                                  navigate(`/upgrade`);
                                }}
                              >Unlock Full Access – Save Your Progress</Button>
                              <span className="pricing-text">Starting at $29/mo</span>
                          </div>
                        </div>
                      )}
                      <Button className="mt-2 mb-4" onClick={() => navigate(`/courses/${id}`)}>
                        Go Back to Course Homepage
                      </Button>
                      {getNextLessonId() && (
                        <Button
                          style={{marginBottom: '17px', marginLeft: '10px'}}
                          onClick={() => {
                            const nextLessonInfo = getNextLessonId();
                            if (nextLessonInfo) {
                              // Capture the "Next Lesson" event in PostHog
                              const { levelId: nextLevelId, lessonId: nextLessonId } = nextLessonInfo;

                              posthog.capture('Next Lesson Clicked', {
                                currentCourseId: id,
                                currentLessonId: lessonId,
                                nextLessonId: nextLessonId
                              });

                              setCurrentStep(0);
                              window.scrollTo(0, 0);
                              // Navigate to the next lesson
                              const basePath = isProjectPath ? '/projects' : '/courses';
                              navigate(`${basePath}/${id}/${nextLevelId}/${nextLessonId}`);
                            }
                          }}
                        >
                          Next Lesson
                        </Button>
                      )}
                    </>
                  )}
                  <br/><br/>
                  <div className="d-block d-md-block" style={{ paddingTop: '4rem' }}></div>
                </div>

                  {showReference && (
                    <>
                      <div className="dimmed-background"></div>
                      <div className="reference-popup">
                        <ShowReference />
                      </div>
                    </>
                  )}
                <div className="button-container position-fixed bottom-0 end-0 m-3">
                  <Button
                    className="btn btn-dark position-fixed bottom-0 end-0 m-3 show-reference-btn"
                    onClick={() => {
                      handleToggleReference();
                      posthog.capture('Clicked Show Reference', {
                        lessonId: lessonId,
                        levelId: levelId,
                        courseId: id,
                      });
                    }}
                  >
                    {showReference ? 'Hide Reference' : 'Show Reference'}
                  </Button>
                </div>
              </>
            )}

            {!userCanView(currentLesson) ? (
              <>
                <div className="app-container  mt-0 pt-4">
                  <h5 className="upgrade-notice p-2 mb-0 bg-white text-center">⭐️ Premium Access Required<span><img src={lockSVG} alt="lock" className="lock"/></span></h5>
                  <Checkout session={session} />
                </div>
              </>
            ) : (
              <div></div>
            )}

          </div>
        </>
      )}
    </>
  );
};

export default Lesson;
